import React from 'react'

const CopyRight = () => {
    return (
        <>
            <div className="copy-right">
                <p>
                    &#169; 2023 Aspire Proptech Consultants OPC Pvt Ltd. All rights reserved    
                </p>
            </div>
        </>
    )
}

export default CopyRight